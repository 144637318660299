<template>
  <div v-if="options">
    <!-- collapse -->
    <app-collapse
      id="faq-payment-qna"
      accordion
      type="margin"
    >

      <app-collapse-item
        v-for="( data,index) in options.details"
        :key="index"
        :title="data.question"
      >
        {{ data.response }}
      </app-collapse-item>

    </app-collapse>

    <!--/ collapse -->
  </div>
</template>

<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    AppCollapseItem,
    AppCollapse,
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
