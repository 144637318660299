<template>
  <div>
    <!-- faq search section -->
    <section id="faq-search-filter">
      <b-card
        no-body
        class="faq-search"
        :style="{backgroundImage:`url(${require('@/assets/images/banner/banner.png')})`}"
      >
        <b-card-body class="text-center">
          <h4 class="text-primary">
            Parcourez les différentes sections pour les questions les plus posées.
          </h4>
        </b-card-body>
      </b-card>
    </section>
    <!--/ faq search section -->

    <!-- frequently asked questions tabs pills -->
    <section id="faq-tabs">
      <b-tabs
        vertical
        content-class="col-12 col-md-8 col-lg-9"
        pills
        nav-class="nav-left"
      >

        <!-- payment tab -->
        <b-tab
          v-for="(categoryObj, index) in faqData"
          :key="index"
          :active="!index"
        >

          <!-- title -->
          <template #title>
            <b-icon
              class="text-primary"
              icon="patch-question"
            />
            <span class="font-weight-bold">{{ categoryObj.section }}</span>
          </template>

          <faq-question-answer :options="categoryObj" />
        </b-tab>
        <!--/ payment tab -->

        <!-- sitting lady image -->
        <!-- <template #tabs-end>
          <b-img
            fluid
            :src="require('@/assets/images/illustration/faq-illustrations.svg')"
            class="d-none d-md-block mt-auto"
          />
        </template> -->
        <!--/ sitting lady image -->
      </b-tabs>
      <div
        v-if="faqData.length > 0"
        class="mt-2"
      >
        <b-pagination
          v-model="currentPage"
          :total-rows="totalItems"
          :per-page="perPage"
          first-number
          last-number
          align="center"
        />
      </div>
    </section>
    <!--/ frequently asked questions tabs pills -->

    <!-- contact us -->
    <section class="faq-contact">
      <b-row class="mt-5 pt-75 text-center">
        <b-col cols="12">
          <h2>Avez vous d'autres questions?</h2>
          <b-card-text class="mb-3">
            Si vous ne trouvez pas de question dans notre FAQ, vous pouvez toujours nous contacter. Nous vous répondrons sous peu!
          </b-card-text>
        </b-col>
        <b-col sm="6">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="PhoneCallIcon"
                size="18"
              />
            </b-avatar>
            <h5>+ (225) 0505370802</h5>
            <span class="text-body">Nous sommes toujours heureux de vous aider!</span>
          </b-card>
        </b-col>
        <b-col sm="6">
          <b-card
            class="shadow-none py-1 faq-contact-card"
          >
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="MailIcon"
                size="18"
              />
            </b-avatar>
            <h5>support@godwinmultiservice.com</h5>
            <span class="text-body">Meilleur moyen d'obtenir une réponse plus rapidement!</span>
          </b-card>
        </b-col>
      </b-row>
    </section>
    <!--/ contact us -->
  </div>
</template>

<script>
import FaqQuestionAnswer from './FaqQuestionAnswer.vue'

export default {
  components: {
    FaqQuestionAnswer,
  },
  data() {
    return {
      perPage: 10,
      totalItems: 0,
      currentPage: 1,
      faqData: [],
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.fetchData()
      },
    },
  },
  methods: {
    async fetchData() {
      this.loaded = true
      this.faqData = await this.$http
        .get(`/faq-all-paged/${this.perPage}?page=${this.currentPage}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.currentToken}`,
            },
          })
        .then(res => {
          const { data } = res
          this.loaded = false
          this.totalItems = data.meta.total
          return data.data
        })
        .catch(() => {
          this.loaded = false
          return []
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-faq.scss';
</style>
